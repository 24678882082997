<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <div v-if="loadedItems.length">
      <!-- Create Payment -->
      <CCard class="create-payment" accent-color="success">
        <CCardHeader
          role="button"
          class="text-success shadow-none card-header"
          @click="() => this.$store.commit('toggle', 'createPaymentShow')"
        >
          <strong class="m-0"><CIcon name="cil-plus" /> Create Payment</strong>
          <div class="card-header-actions">
            <CIcon :name="`cil-chevron-${$store.state.createPaymentShow ? 'bottom' : 'top'}`"/>
          </div>
        </CCardHeader>

        <CCollapse :show="$store.state.createPaymentShow">
          <CCardBody class="p-2">
            <div class="d-flex flex-md-row flex-column" style="gap: 0.5rem;">
              <!-- Amount -->
              <CInput
                class="mb-0"
                :value.sync="$v.form.amount.$model"
                :lazy="false"
                placeholder="0.00"
                type="number"
                step="0.01"
                pattern="^\d+(?:\.\d{1,2})?$"
                onblur="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
                :isValid="checkIfValid('amount')"
              >
                <template #prepend-content><CIcon name="cil-euro" /></template>
              </CInput>

              <!-- Type -->
              <CInputRadioGroup
                name="type"
                :options="[
                  { value: '0', label: 'Received' },
                  { value: '1', label: 'Paid' }
                ]"
                :checked.sync="$v.form.type.$model"
                custom
                inline
              />

              <!-- Restaurant -->
              <CSelect
                class="flex-grow-1 mb-0"
                custom
                placeholder="Please choose a restaurant.."
                :options="allRestaurants"
                :value.sync="$v.form.restaurant.$model"
                :isValid="checkIfValid('restaurant')"
              />
              <!-- <div class="flex-grow-1">
                <v-select
                  class="v-select-create-payment"
                  placeholder="Select restaurant.."
                  v-model="form.restaurant"
                  :options="allRestaurants"
                />
              </div> -->

              <CButton
                size="sm"
                color="success"
                @click="createPayment"
              >
                Create Payment
              </CButton>
            </div>
          </CCardBody>
        </CCollapse>
      </CCard>

      <!-- Filters -->
      <CCard class="filters" accent-color="warning">
        <CCardHeader
          role="button"
          class="text-warning shadow-none card-header"
          @click="resetFilters"
        >
          <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
          <div class="card-header-actions">
            <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
          </div>
        </CCardHeader>

        <CCollapse :show="$store.state.filterShow">
          <CCardBody class="p-2">
            <div class="d-flex flex-md-row flex-column" style="gap: 0.5rem;">
              <!-- Restaurant Filter -->
              <div class="flex-grow-1">
                <v-select
                  class="v-select-filter"
                  placeholder="Select restaurant.."
                  v-model="selectedRestaurant"
                  :options="allRestaurants"
                  @input="restaurantFilter"
                />
              </div>

              <!-- Pagination -->
              <div v-if="pages > 1">
                <CPagination
                  align="end"
                  :dots='false'
                  :pages="pages"
                  :active-page.sync="activePage"
                />
              </div>
            </div>
          </CCardBody>
        </CCollapse>
      </CCard>

      <CDataTable
        striped
        hover
        :items="loadedItems"
        :fields="fields"
        :sorter="{ external: true, resetable: true }"
        :table-filter="{ external: true, lazy: true, placeholder: '...' }"
        :tableFilterValue.sync="search"
        :column-filter="{ external: true, lazy: true }"
        :itemsPerPageSelect="{
          external: true,
          values: [5, 15, 25, 50, 100, 250, 500],
        }"
        :items-per-page.sync="itemsPerPage"
        :loading="loading"
        :noItemsView="{
          noResults: 'No filtering results are available!',
          noItems: 'No payments found!',
        }"
        @update:sorter-value="sorterValue"
        @update:table-filter-value="tableSearch"
        @pagination-change="paginationChange"
      >

        <!-- ID -->
        <template #id="{item}">
          <td>
            #{{ item?.id }}
          </td>
        </template>

        <!-- Restaurant -->
        <template #restaurant="{item}">
          <td>
            #{{ item.restaurant?.id }}
            <CLink class="font-weight-bold" target="_blank" :to="{name: 'View Restaurant',params: { id: item.restaurant?.id }}">
              {{ item.restaurant?.restaurant_name }}
            </CLink>
          </td>
        </template>

        <!-- Amount -->
        <template #apamount="{item}">
          <td class="font-weight-bold">
            {{ item.aptype == 0 ? "+ " : "- " }}{{ item.apamount | toCurrency }}
          </td>
        </template>

        <!-- Date (apdate) Filter -->
        <template #apdate-filter>
          <input
            type="date"
            v-model="dateFilter"
            class="w-min"
          />
        </template>

        <!-- Date (apdate) -->
        <template #apdate="{item}">
          <td>
            {{ item.apdate }}
          </td>
        </template>

        <!-- Notes -->
        <template #apnote="{item}">
          <td>
            {{ item.apnote }}
          </td>
        </template>

        <!-- Actions -->
        <template #actions="{ item, index }">
          <td class="py-2 text-center">
            <CButton
              size="sm"
              color="danger"
              shape="pill"
              @click="deleteItem(item, index)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </CDataTable>

      <CCard class="actions sticky-bottom">
        <CCardBody class="p-2">
          <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
            <div>
              <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
            </div>

            <div v-if="pages > 1" class="ml-auto">
              <CPagination
                align="center"
                :dots='false'
                :pages="pages"
                :active-page.sync="activePage"
              />
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div v-else>
      <CSpinner v-if="loading" color="primary" />
      <h6 v-else>No payments found!</h6>
    </div>
  </CCardBody>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import _debounce from 'lodash/debounce';

export default {
  name: "BackendTable",
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "actions",       label: "Actions",     filter: false, sorter: false, _style: "width: 60px; text-align: center;" },
        { key: "id",            label: "ID",          filter: false, _style: "min-width: 100px;" },
        { key: "apamount",      label: "Amount",      filter: false, _style: "width: 200px;" },
        { key: "apdate",        label: "Date",        filter: true, _style: "width: 200px;" },
        { key: "apnote",        label: "Notes",       filter: false, _style: "min-width: 200px;" },
        { key: "restaurant",    label: "Restaurant",  filter: false, _style: "min-width: 240px;", sorter: false },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,
      submitted: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Restaurants Filter işlemleri için
      allRestaurants: [],
      selectedRestaurant: [],

      // Date Filter
      dateFilter: null,

      // Create Payment Form
      form: {},
    };
  },

  async beforeMount() {
    this.filterByDate = _debounce(async () => {
      await this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage = this.queryPage;
    this.getForm();
    await this.getAllRestaurants();
    if(this.restaurantId) {
      this.selectedRestaurant = this.allRestaurants.find((r) => r.value == this.restaurantId)
    }
    await this.getAllItems();
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage) {
        this.$router.push({ name: this.$route.name, query: { page: this.activePage } });
      }
      this.onTableChange();
    },
    dateFilter() {
      this.filterByDate();
    }
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    reloadParams() { return [this.activePage] },
    queryPage() { return parseInt(this.$route.query.page) || 1 },
    restaurantId () { return this.$route.params.restaurant_id },
  },

  mixins: [validationMixin],

  validations: {
    form: {
      amount: { required },
      type: { },
      restaurant: { required },
    }
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.PAYMENTS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          restaurant: (this.selectedRestaurant && this.selectedRestaurant.value) ? this.selectedRestaurant.value : null,
          dateFilter: this.dateFilter,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getAllRestaurants() {
      this.loading = true;
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.allRestaurants = response.data;
        })
        .catch(() => { this.allRestaurants = [] })
        .finally(() => this.loading = false);
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    tableSearch(search) {
      // if(search && search != '')
      //   this.selectedInvoiceFilter = ['all'];
      this.getAllItems();
    },

    // Filters
    restaurantFilter(value) {
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedRestaurant = [];
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    // Create Payment
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
    validate () {
      this.$v.$touch()
    },
    reset () {
      this.getForm()
      this.showErrorAlert = false
      this.submitted = false
      this.$v.$reset()
    },
    getForm () {
      this.form = {
        amount: 0,
        type: '1',
        restaurant: null,
      }
    },
    async createPayment() {
      if (!this.isValid) {
        this.validate();
        return;
      }

      this.submitted = true;
      await this.$axios
        .post(this.$backend.PAYMENTS.STORE, this.form)
        .then((response) => {
          this.getAllItems();
          this.showErrorAlert = false;
          this.successAlertMessage = {itemName: 'Payment', message: 'has been successfully created.'};
          this.dismissSuccessAlert = this.dismissSecs;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data;
          this.showErrorAlert = true;
        })
        .finally(() => this.reset());
    },

    // Delete Payment
    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            this.$axios
              .delete(this.$backend.PAYMENTS.DELETE.replace("{id}", item?.id))
              .then((response) => {
                this.$delete(this.loadedItems, index);

                this.showErrorAlert = false
                this.successAlertMessage = {itemName: 'Payment', message: 'deleted.'}
                this.dismissSuccessAlert = this.dismissSecs
                this.total--
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data;
                this.showErrorAlert = true;
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
          }
        },
      });
    },
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
@media (min-width: 576px) {
  input[aria-label='table filter input'] {
    width: 280px !important;
  }
}

/* Vue-Select Create Payment */
.v-select-create-payment .vs__search {
  line-height: 1.7;
}
.v-select-create-payment .vs__clear {
  margin-top: -3px;
}
</style>

<style scoped>
/* Date Filter */
.w-min {
  width: 100px;
}
</style>
